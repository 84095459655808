<template>
  <div>
    <!--begin::Advance Table Widget 9-->
    <div class="card card-custom card-stretch gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">
            Files
          </span>
          <span class="text-muted mt-3 font-weight-bold font-size-sm"></span>
        </h3>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-0 pb-3">
        <b-table
          responsive
          striped
          hover
          :items="items"
          :fields="fields"
          :busy="isBusy"
          empty-text="No records"
          id="files-table"
        >
          <template v-slot:cell(action)="data">
            <a
              :href="data.item.url"
              target="_blank"
              class="btn btn-success font-size-sm text-uppercase"
            >
              <i class="flaticon2-download"></i> Download
            </a>
          </template>
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
        </b-table>
        <b-pagination
          v-model="pagination.current_page"
          :total-rows="pagination.total"
          :per-page="pagination.per_page"
          :disabled="isBusy"
          aria-controls="files-table"
          @change="reloadPage($event)"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { LIST_FILES } from "@/core/services/store/file.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "FilesList",
  data() {
    return {
      items: [],
      pagination: { current_page: 1, total: 0, per_page: 15 },
      fields: [
        {
          key: "uuid",
          sortable: true,
          label: "UUID"
        },
        {
          key: "template.name",
          sortable: true,
          label: "Template"
        },
        {
          key: "action",
          label: "Action",
          sortable: false
        }
      ],
      isBusy: false
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Files" }]);

    this.isBusy = true;
    this.$store.dispatch(LIST_FILES).then(() => {
      this.isBusy = false;
    });

    this.items = this.files;
  },
  methods: {
    reloadPage(page) {
      this.isBusy = true;
      this.$store.dispatch(LIST_FILES, { page }).then(() => {
        this.isBusy = false;
      });
    }
  },
  computed: {
    ...mapGetters(["files", "meta"])
  },
  watch: {
    files(newValue, oldValue) {
      if (newValue.length != oldValue.length) {
        this.items = newValue;
      }
    },
    meta(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.pagination = newValue;
      }
    }
  }
};
</script>

<style scoped></style>
